Vue.component('profileComponent',{
    data:function(){
        return {
            name:null,
            lastname:null,
            email:null,
            phone:null
        }
    },
    methods:{
        async skipInputs(){
            console.log('Skip Option');
            let currentInfo = this.$store.state.session.fields;
            /*let response = await this.$store.dispatch('updateUserInfo',{
                name:currentInfo.Name,
                lastname:currentInfo.LastName,
                email:currentInfo.Email,
                phone:currentInfo.Phone
            });*/

            /*this.$store.commit('updateGenericUser',!response.success);
            this.$store.commit('updateSession',response.person);
            if(response.success){
              this.$router.push('/');
            }
            */
            let response = await this.$store.dispatch('logout',{
                name:currentInfo.Name,
                lastname:currentInfo.LastName,
                email:currentInfo.Email,
                phone:currentInfo.Phone
            });
            this.$router.push('/')                  
        },
        async processInputs(){
            if(!this.name==null || this.lastname==null || this.email==null || this.phone==null){
                Materialize.toast(this.tr('Please fill all the Fields'),5000);
            }
            else{
                let response = await this.$store.dispatch('updateUserInfo',{
                    name:this.name,
                    lastname:this.lastname,
                    email:this.email,
                    phone:this.phone
                });

                this.$store.commit('updateGenericUser',!response.success);
                this.$store.commit('updateSession',response.person);
                if(response.success){
                    this.$router.push('/#');
                }
            }
        }
    },
    template:`<div class="items-view">
                  <headerSectionComponent headerTitle="" :renderMenu="false" :renderSearch="false"></headerSectionComponent>
                  <div class="section white">
                      <div class="container">
                        <div class="row">
                            <div class="col s12 m8 offset-l2">
                                <h5>{{tr('Please complete the following information to enter the page')}}:</h5>
                                <div class="row">
                                    <div class="input-field col s12">
                                      <i class="material-icons prefix">account_circle</i>
                                      <input v-model="name" id="name_prefix" type="text"  class="validate">
                                      <label for="name_prefix">{{tr('Name')}}</label>
                                    </div>
                                    <div class="input-field col s12">
                                      <i class="material-icons prefix">account_circle</i>
                                      <input v-model="lastname" id="lastname_prefix" type="text" class="validate">
                                      <label for="lastname_prefix">{{tr('Last Name')}}</label>
                                    </div>
                                    <div class="input-field col s12">
                                      <i class="material-icons prefix">email</i>
                                      <input v-model="email" id="icon_email" type="email" class="validate">
                                      <label for="icon_email">{{tr('Email')}}</label>
                                    </div>
                                    <div class="input-field col s12">
                                      <i class="material-icons prefix">phone</i>
                                      <input v-model="phone" id="icon_telephone" type="text" class="validate">
                                      <label for="icon_telephone">{{tr('Phone')}}</label>
                                    </div>
                                    <div class="input-field col s12">
                                      <button class="btn" @click="processInputs">Continuar a la Página</button>
                                      <button class="btn" @click="skipInputs">Cancelar</button>
                                    </div>
                                </div>
                            </div>
                         </div>
                      </div>
                  </div>
              </div>`
});